import type {
    UseQueryResult,
    QueryKey,
    UseQueryOptions as UseQueryInitialOptions,
} from "@tanstack/react-query";
import { useQuery as useQueryInitial } from "@tanstack/react-query";
import { useContext, useEffect } from "react";

import { useReactQueryOnError } from "@/Support/Hooks/useReactQueryOnError";
import { useReactQueryOnSuccess } from "@/Support/Hooks/useReactQueryOnSuccess";

import Context from "@/Pages/Context";

import type { RequestError } from "../Requests/graphQLClient";

export type UseQueryOptions<
    TQueryFnData = unknown,
    TError extends RequestError = RequestError,
    TData = TQueryFnData,
    TQueryKey extends QueryKey = QueryKey,
> = Omit<
    UseQueryInitialOptions<TQueryFnData, TError, TData, TQueryKey>,
    "queryFn" | "queryKey"
>;

export const useQuery = <
    TQueryFnData = unknown,
    TError extends RequestError = RequestError,
    TData = TQueryFnData,
    TQueryKey extends QueryKey = QueryKey,
>(
    options: UseQueryInitialOptions<TQueryFnData, TError, TData, TQueryKey>,
): UseQueryResult<TData, TError> => {
    const onError = useReactQueryOnError("query");

    const onSuccess = useReactQueryOnSuccess();

    const { maintenanceMode } = useContext(Context);

    const query = useQueryInitial({
        ...options,
        retry: (maintenanceMode || options.retry) ?? false,
    });

    useEffect(() => {
        if (!query.isSuccess) return;
        onSuccess();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [query.isSuccess]);

    useEffect(() => {
        if (!query.error) return;
        onError(query.error);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [query.error]);

    return query;
};
