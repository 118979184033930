import type { HTMLAttributes, ReactNode } from "react";
import styled, { keyframes } from "styled-components";

import { Text } from "@/Components/Text";

const Container = styled.div`
    align-items: center;
    border-radius: 16px;
    box-shadow: var(--shadow-base), var(--shadow-radius-32);
    display: flex;
    flex-direction: column;
    gap: 32px;
    margin: auto;
    max-width: 500px;
    padding: 60px 80px;
`;

const Title = styled(Text.H3)`
    color: var(--color-neutral-700);
    font-weight: 500;
`;

const Spin = keyframes`
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(600deg);
    }
`;

const WheelContainer = styled.div`
    border-radius: 50%;
    box-shadow: var(--shadow-base), var(--shadow-radius-16);
`;

const SpinWheel = styled.img.attrs({
    height: 120,
    width: 120,
    src: "/Wheel.svg",
})`
    animation: ${Spin} 2.4s cubic-bezier(0.5, 1, 0.89, 1) infinite;
    animation-delay: 0.3s;
`;

export const LoadingTemplate = ({
    title,
    children,
    ...otherProps
}: Omit<HTMLAttributes<HTMLDivElement>, "title"> & {
    readonly title?: ReactNode;
}): JSX.Element => (
    <Container {...otherProps}>
        <WheelContainer>
            <SpinWheel />
        </WheelContainer>
        <Title>{title}</Title>
        {children}
    </Container>
);
