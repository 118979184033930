export enum Currency {
    EUR = "eur",
    USD = "usd",
    // reverse mapping for mutations
    eur = "EUR",
    usd = "USD",
}

export enum UserRole {
    ADMIN = "ADMIN",
    EDITOR = "EDITOR",
    OWNER = "OWNER",
}

export enum Release {
    PUSH_NOTIFICATIONS = "PUSH_NOTIFICATIONS",
    HOME = "HOME",
    ON_DEMAND_PAGEVIEWS = "ON_DEMAND_PAGEVIEWS",
    POPUP_GALLERY = "POPUP_GALLERY",
}
