import type { GraphQLErrorExtensions } from "graphql";
import type {
    RequestDocument,
    RequestOptions,
    Variables,
} from "graphql-request";
import { GraphQLClient, request as gqlRequest } from "graphql-request";
import type { TypedDocumentNode } from "msw/lib/core/graphql";

/** GraphQL queries/mutations operations names */
export enum GraphQLQuery {
    MeQuery = "MeQuery",
    AccountSetupQuery = "AccountSetupQuery",
    AccountSetupInformationsMutation = "AccountSetupInformationsMutation",
    AlertsQuery = "AlertsQuery",
    WorkspaceQuery = "WorkspaceQuery",
    WorkspaceGoalsSettingsQuery = "WorkspaceGoalsSettingsQuery",
    UserQuery = "UserQuery",
    UserBasicsQuery = "UserBasicsQuery",
    UserWebsitesQuery = "UserWebsitesQuery",
    UsersQuery = "UsersQuery",
    UsersBasicsQuery = "UsersBasicsQuery",
    WebsitesQuery = "WebsitesQuery",
    WebsitesCountQuery = "WebsitesCountQuery",
    WebsitesBasicsQuery = "WebsitesBasicsQuery",
    WebsiteQuery = "WebsiteQuery",
    WebsiteBasicsQuery = "WebsiteBasicsQuery",
    WebsiteEditorsQuery = "WebsiteEditorsQuery",
    WebsiteWispQuery = "WebsiteWispQuery",
    WebsiteNotificationSettingsQuery = "WebsiteNotificationSettingsQuery",
    WebsiteNotificationSettingsMutation = "WebsiteNotificationSettingsMutation",
    WebsiteNotificationSettingsBellUploadMutation = "WebsiteNotificationSettingsBellUploadMutation",
    WebsiteWisepopsQuery = "WebsiteWisepopsQuery",
    WebsiteInstallationQuery = "WebsiteInstallationQuery",
    WebsiteAnalyticsQuery = "WebsiteAnalyticsQuery",
    WebsiteAnalyticsSummaryQuery = "WebsiteAnalyticsSummaryQuery",
    WebsiteAnalyticsFilterAutocompleteQuery = "WebsiteAnalyticsFilterAutocompleteQuery",
    WebsiteAnalyticsCampaignsCountQuery = "WebsiteAnalyticsCampaignsCountQuery",
    WebsiteAnalyticsCampaignsQuery = "WebsiteAnalyticsCampaignsQuery",
    WebsiteAnalyticsCampaignsBasicsQuery = "WebsiteAnalyticsCampaignsBasicsQuery",
    WebsiteAnalyticsCampaignsExportMutation = "WebsiteAnalyticsCampaignsExportMutation",
    WebsiteTopMetricsQuery = "WebsiteTopMetricsQuery",
    WebsiteTopCampaignsQuery = "WebsiteTopCampaignsQuery",
    WebsiteShopifyQuery = "WebsiteShopifyQuery",
    BillingQuery = "BillingQuery",
    BillingExtendedQuery = "BillingExtendedQuery",
    BillingPageviewsQuery = "BillingPageviewsQuery",
    BillingEmailQuery = "BillingEmailQuery",
    BillingExtraPageviewsMutation = "BillingExtraPageviewsMutation",
    BillingEditorPageviewsQuery = "BillingEditorPageviewsQuery",
    UpcomingInvoiceQuery = "UpcomingInvoiceQuery",
    GoalsQuery = "GoalsQuery",
    GoalQuery = "GoalQuery",
    GoalsBasicsQuery = "GoalsBasicsQuery",
    WebsitePushSettingsQuery = "WebsitePushSettingsQuery",
    CreateGoalMutation = "CreateGoalMutation",
    UpdateGoalMutation = "UpdateGoalMutation",
    DeleteGoalMutation = "DeleteGoalMutation",
    PlansQuery = "PlansQuery",
    SubscribeMutation = "SubscribeMutation",
    UpdateBillingEmailMutation = "UpdateBillingEmailQuery",
    UnsubscribeMutation = "UnsubscribeMutation",
    LoginGoogleMutation = "LoginGoogleMutation",
    SetupAccountMutation = "SetupAccountMutation",
    LoginMutation = "LoginMutation",
    DeleteUsersMutation = "DeleteUsersMutation",
    UpdateRoleMutation = "UpdateRoleMutation",
    InviteUserMutation = "InviteUserMutation",
    ResendInviteMutation = "ResendInviteMutation",
    UpdateWebsiteMutation = "UpdateWebsiteMutation",
    UpdateWebsitePushSettingsMutation = "UpdateWebsitePushSettingsMutation",
    CreateWebsiteMutation = "CreateWebsiteMutation",
    DeleteWebsiteMutation = "DeleteWebsiteMutation",
    ResendEmailMutation = "ResendEmailMutation",
    UpdateAccountNameMutation = "UpdateAccountNameMutation",
    UpdateAccountEmailMutation = "UpdateAccountEmailMutation",
    DeleteAccountPictureMutation = "DeleteAccountPictureMutation",
    UpdateAccountPictureMutation = "UpdateAccountPictureMutation",
    CheckLoginMutation = "CheckLoginMutation",
    SendInstructionEmailMutation = "SendInstructionEmailMutation",
    SendBellRequestMutation = "SendBellRequestMutation",
    UpdateGoalsSettingsMutation = "UpdateGoalsSettingsMutation",
    SendCancellationRequestMutation = "SendCancellationRequestMutation",
}

/** In case request fails the response type will be RequestError. It contains a key `code` with the request HTTP code value. Additional keys for validation can be added. eg: RequestError<{ email: string[] }> */
export type RequestError<T = Record<string, never>> = {
    code: number;
    message?: string;
    validation: Partial<T>;
};

/** We either mock the API when VITE_API_MOCK_ENABLED is set to "true" or when running code through vitest package.*/
const isMocking =
    import.meta.env.VITE_API_MOCK_ENABLED === "true" ||
    import.meta.env.VITEST === "true";

/** If mocking is enabled, url will target worker service. */
const graphQLUrl = isMocking ? import.meta.env.VITE_API_MOCK_URL : "/graphql";

/** To prevent CORS policy issues, we need to omit credentials when mocking requests. */
const credentials: RequestCredentials = isMocking ? "omit" : "include";

export const graphQLClient = new GraphQLClient(graphQLUrl, {
    credentials,
});

/** Custom graphql-request request function formatting error response. */
export const request = async <
    R,
    V = Record<string, unknown> | undefined,
>(options: {
    document: RequestDocument | TypedDocumentNode<V, R>;
    signal?: AbortSignal;
    variables?: V;
}): Promise<R> =>
    gqlRequest<R>({
        ...options,
        variables: options.variables as Variables,
        signal: options.signal as RequestOptions["signal"],
        url: `${graphQLUrl}?op=${
            /^(?:query |mutation )(.+)\(/.exec(
                options.document as string,
            )?.[1] ?? ""
        }`,
    }).catch(
        async (
            error: Error & {
                response?: Awaited<
                    ReturnType<typeof graphQLClient.rawRequest>
                > & {
                    message?: string;
                };
            },
        ) => {
            const errors = error.response?.errors ?? [];

            /** Formatting error state according custom wisepops backend format. */
            const validation = errors.reduce<Record<string, string[]>>(
                (obj, e) => {
                    const extensions = e.extensions as
                        | (GraphQLErrorExtensions & {
                              validation_errors: Record<string, string[]>;
                          })
                        | undefined;

                    return { ...obj, ...extensions?.validation_errors };
                },
                {},
            );
            const obj: RequestError<Record<string, string[]>> = {
                code: error.response?.status ?? 500,
                message:
                    error.response?.message || errors[0]?.message || undefined,
                validation,
            };

            return Promise.reject(obj);
        },
    );
