import { gql } from "graphql-request";

import type { UseQueryOptions } from "@/Support/Hooks/useQuery";
import { useQuery } from "@/Support/Hooks/useQuery";

import type { UserRole, Release } from "@/Pages/types";

import { request, GraphQLQuery } from "./graphQLClient";
import { ReactQueryKeys } from "./reactQueryKeys";

export interface Workspace {
    id: number;
    created_at: string;
    company_name: string;
    releases: Release[];
}

export interface MeResponse {
    id: number;
    name: string | null;
    email: string;
    unverifiedEmail: string | null;
    intercom_user_hash: string;
    is_impersonating: boolean;
    nova_route: string | null;
    telescope_route: string | null;
    stop_impersonate_route: string | null;
    popups_route: string | null;
    popups_impersonator_route: string | null;
    notification_impersonator_route: string | null;
    last_active_at: string | null;
    created_at: string;
    picture: string | null;
    workspaces: {
        items: {
            workspace: Workspace;
            role: UserRole;
            is_setup: boolean;
        }[];
    };
}

export interface MeGraphQLResponse {
    me: MeResponse;
}

export type MeGraphQLVariables = never;

/** Initial request made to API to check if user is logged in and to get user and workspace information */
export const useMe = (options?: UseQueryOptions<MeResponse>) =>
    useQuery<MeResponse>({
        queryKey: [ReactQueryKeys.ME, GraphQLQuery.MeQuery],
        queryFn: async ({ signal }) => {
            const { me } = await request<MeGraphQLResponse>({
                document: gql`query ${GraphQLQuery.MeQuery} {
                    me {
                        id
                        name
                        email
                        last_active_at
                        created_at
                        picture: picture_url
                        unverifiedEmail: unverified_email
                        intercom_user_hash
                        is_impersonating
                        nova_route
                        telescope_route
                        stop_impersonate_route
                        popups_route
                        popups_impersonator_route
                        notification_impersonator_route
                        workspaces: userWorkspace(pagination: {}) {
                            items {
                                workspace {
                                    id
                                    created_at
                                    company_name: metadataCompanyName
                                    releases
                                }
                                role
                                is_setup: metadataIsSetup
                            }
                        }
                    }
                }
            `,
                signal,
            });

            return me;
        },
        ...options,
    });
